/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react"
import { useGLTF } from "@react-three/drei"

export default function Model(props) {
  const { nodes, materials } = useGLTF("/3d/bedroom2.gltf")
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Floor.geometry}
        material={materials.White}
        scale={[4, 0.2, 4]}
      />
      <group position={[-0.007, 0.204, 3.486]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube008.geometry}
          material={materials.Frame}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube008_1.geometry}
          material={materials.Blanket}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube008_2.geometry}
          material={materials["Pin picture.001"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Pillow001.geometry}
        material={materials.Pillow}
        position={[0.153, 1.239, 3.649]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Pillow002.geometry}
        material={materials.Pillow}
        position={[0.59, 1.285, 3.771]}
        rotation={[-1.18, 0.143, 0.13]}
      />
      <group position={[2.63, 0.204, 3.657]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube016.geometry}
          material={materials.Frame}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube016_1.geometry}
          material={materials["Pin picture.001"]}
        />
      </group>
      <group position={[3.378, 1.88, 3.675]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube020.geometry}
          material={materials.White}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube020_1.geometry}
          material={materials.Red}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube020_2.geometry}
          material={materials.Blue}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube020_3.geometry}
          material={materials.Yellow}
        />
      </group>
      <group position={[2.587, 2.612, 3.682]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube022.geometry}
          material={materials.White}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube022_1.geometry}
          material={materials.Yellow}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube022_2.geometry}
          material={materials.Red}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube022_3.geometry}
          material={materials.Blue}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Coffe_table.geometry}
        material={materials.Frame}
        position={[1.197, 0.554, -0.881]}
      />
      <group position={[1.186, 0.208, -0.871]} rotation={[0, 0.455, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane.geometry}
          material={materials.Pillow}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane_1.geometry}
          material={materials.White}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cushion.geometry}
        material={materials.Blanket}
        position={[2.772, 0.282, -0.848]}
        rotation={[0, 0.349, 0]}
      />
      <group position={[3.394, 1.271, 3.672]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube028.geometry}
          material={materials.White}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube028_1.geometry}
          material={materials.Red}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube028_2.geometry}
          material={materials.Blue}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube028_3.geometry}
          material={materials.Yellow}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cushion001.geometry}
        material={materials.Blanket}
        position={[1.416, 0.282, -2.391]}
        rotation={[-Math.PI, 1.403, -Math.PI]}
      />
      <group position={[-3.568, 0.174, -3.313]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube035.geometry}
          material={materials.Blanket}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube035_1.geometry}
          material={materials.Pillow}
        />
      </group>
      <group position={[2.496, 1.877, 3.672]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube040.geometry}
          material={materials.White}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube040_1.geometry}
          material={materials.Blue}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube040_2.geometry}
          material={materials.Red}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube040_3.geometry}
          material={materials.Yellow}
        />
      </group>
      <group position={[-1.008, 1.057, -0.464]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube052.geometry}
          material={materials.Blanket}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube052_1.geometry}
          material={materials.Frame}
        />
      </group>
      <group position={[1.687, 0.667, -0.803]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder005.geometry}
          material={materials.White}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder005_1.geometry}
          material={materials.Coffe}
        />
      </group>
      <group position={[1.306, 0.684, -1.454]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder006.geometry}
          material={materials.White}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder006_1.geometry}
          material={materials.Coffe}
        />
      </group>
      <group position={[2.568, 1.491, 3.646]} rotation={[0, 0.315, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube015.geometry}
          material={materials.Frame}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube015_1.geometry}
          material={materials.White}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube015_2.geometry}
          material={materials["Pin picture.002"]}
        />
      </group>
      <group position={[1.911, 1.491, 3.646]} rotation={[0, -0.354, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube019.geometry}
          material={materials.Frame}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube019_1.geometry}
          material={materials.White}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube019_2.geometry}
          material={materials["Pin picture"]}
        />
      </group>
      <group position={[-3.419, 0.205, 3.478]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder.geometry}
          material={materials.Coffe}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder_1.geometry}
          material={materials.Blanket}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder_2.geometry}
          material={materials.Leaves}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder_3.geometry}
          material={materials.Frame}
        />
      </group>
      <group position={[-4.004, 3.051, -1.324]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube005.geometry}
          material={materials["White.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube005_1.geometry}
          material={materials["Pin picture.005"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube005_2.geometry}
          material={materials["Frame.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube005_3.geometry}
          material={materials["Board.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube005_4.geometry}
          material={materials["Red.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube005_5.geometry}
          material={materials["Pin picture.004"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube005_6.geometry}
          material={materials["Yellow.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube005_7.geometry}
          material={materials["Pin picture.003"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube005_8.geometry}
          material={materials["Blue.001"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube.geometry}
        material={materials["Material.001"]}
        position={[-4.19, 2.91, 0.13]}
        rotation={[0, Math.PI / 2, 0]}
        scale={[4.14, 3.13, 0.2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube001.geometry}
        material={materials["Material.002"]}
        position={[-0.12, 2.91, 4.23]}
        scale={[4.14, 3.13, 0.2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wallshelf.geometry}
        material={materials.Frame}
        position={[-3.77, 3.25, 2.269]}
      />
      <group position={[-3.717, 3.193, 2.867]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder001.geometry}
          material={materials.Red}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder001_1.geometry}
          material={materials.Leaves}
        />
      </group>
      <group position={[-3.74, 2.79, 1.657]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder002.geometry}
          material={materials.Blue}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder002_1.geometry}
          material={materials.Leaves}
        />
      </group>
    </group>
  )
}

useGLTF.preload("/3d/bedroom2.gltf")
