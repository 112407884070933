/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import React, { useRef, useState, useEffect } from "react"
import { useGLTF, Text } from "@react-three/drei"
import { Select } from "@react-three/postprocessing"
import { useNavigate } from "react-router-dom"
import { useFrame, useThree } from "@react-three/fiber"
import { useSpring, a } from "@react-spring/three"

export default function Model(props) {
  const { nodes, materials } = useGLTF("/3d/biurko2.gltf")
  const [color, setColor] = useState("white")
  const [color2, setColor2] = useState("white")
  const [color3, setColor3] = useState("white")
  const [color4, setColor4] = useState("black")
  // Ustawienie stanu do wyświetlania napisu
  const [showText, setShowText] = useState(false)
  const [showText2, setShowText2] = useState(false)
  const [showText3, setShowText3] = useState(false)
  const [showText4, setShowText4] = useState(false)

  const [printText, setPrintText] = useState("")
  const [rightMonitorText, setRightMonitorText] = useState("")

  const [hovered, setHovered] = useState(false)

  const groupTextRef = useRef(null)

  const navigate = useNavigate()

  useFrame(({ clock }) => {
    if (groupTextRef.current) {
      // Dodajemy sprawdzenie, czy textRef.current istnieje
      const scale = 1 + 0.1 * Math.sin(clock.getElapsedTime() * 2) // Skalowanie od 1 do 1.2
      groupTextRef.current.scale.set(scale, scale, scale)
    }
  })

  const ExperienceTextRef = useRef(null)

  // const handleClickPosition = () => {
  //   api.start({ position: [2, 1, 5] })
  // }

  const DownloadButton = async () => {
    // try {
    //   const response = await fetch("/AdamMisiura-CVpl.pdf") // Ścieżka do pliku PDF w `public` lub pełny URL
    //   const blob = await response.blob()
    //   const url = window.URL.createObjectURL(blob)
    //   const a = document.createElement("a")
    //   a.href = url
    //   a.download = "/AdamMisiura-CVpl.pdf"
    //   document.body.appendChild(a)
    //   a.click()
    //   a.remove()
    //   window.URL.revokeObjectURL(url)
    // } catch (error) {
    //   console.error("Błąd pobierania PDF:", error)
    // }
  }

  const [scale, setScale] = useState([0.423, 1.679, 1.009])
  // Funkcja obsługująca kliknięcie
  const handleClick = () => {
    setShowText(!showText) // Przełączanie widoczności napisu
    setShowText2(false)
    setShowText3(false)
    setShowText4(false)
    setColor2("white")
    setColor3("white")
    setColor4("black")
    if (showText) {
      setColor("white")
    } else {
      setColor("#c0512d")
      props.moveAngle()
      props.selectAnimation(3)
    }
  }

  const handleClick2 = () => {
    setShowText2(!showText2) // Przełączanie widoczności napisu
    setShowText(false)
    setShowText3(false)
    setShowText4(false)
    setColor("white")
    setColor3("white")
    setColor4("black")
    if (showText2) {
      setColor2("white")
    } else {
      setColor2("#c0512d")
      props.moveAngle()
      props.selectAnimation(4)
    }
  }

  const handleClick3 = () => {
    setShowText3(!showText3) // Przełączanie widoczności napisu
    setShowText(false)
    setShowText2(false)
    setShowText4(false)
    setColor("white")
    setColor2("white")
    setColor4("black")
    if (showText3) {
      setColor3("white")
    } else {
      setColor3("#c0512d")
      props.moveAngle()
      props.selectAnimation(6)
    }
  }

  const handleClick4 = () => {
    setShowText4(!showText4) // Przełączanie widoczności napisu
    setShowText(false)
    setShowText2(false)
    setShowText3(false)
    setColor("white")
    setColor3("white")
    setColor2("white")
    if (showText4) {
      setColor4("black")
    } else {
      setColor4("#c0512d")
      props.selectAnimation(5)
      props.moveAngle()
    }
  }

  return (
    <group {...props} dispose={null}>
      <Text
        position={[-2.8, 1.8, -2.7]} // Pozycja wyświetlanego tekstu
        fontSize={0.2}
        color={color} // Używamy stanu koloru
        castShadow
        onPointerOver={() => (color === "#c0512d" ? null : setColor("orange"))}
        // Przywracamy kolor po opuszczeniu
        onPointerOut={() => (color === "#c0512d" ? null : setColor("white"))}
        onClick={handleClick}
        fontWeight={"bold"}
        fontStyle={"italic"}
      >
        Edukacja
      </Text>

      <Text
        position={[-1.6, 0.65, -2.7]} // Pozycja wyświetlanego tekstu
        fontSize={0.2}
        color={color3} // Używamy stanu koloru
        castShadow
        onPointerOver={() =>
          color3 === "#c0512d" ? null : setColor3("orange")
        }
        // Przywracamy kolor po opuszczeniu
        onPointerOut={() => (color3 === "#c0512d" ? null : setColor3("white"))}
        onClick={handleClick3}
        fontWeight={"bold"}
        fontStyle={"italic"}
      >
        Technologie
      </Text>
      <Text
        position={[0.3, 2.1, -0.7]} // Pozycja wyświetlanego tekstu
        fontSize={0.2}
        rotation={[-Math.PI / 2, -Math.PI / 2, -Math.PI / 2]}
        color={color2} // Używamy stanu koloru
        castShadow
        onPointerOver={() =>
          color2 === "#c0512d" ? null : setColor2("orange")
        }
        // Przywracamy kolor po opuszczeniu
        onPointerOut={() => (color2 === "#c0512d" ? null : setColor2("white"))}
        onClick={handleClick2}
        fontWeight={"bold"}
        fontStyle={"italic"}
        ref={ExperienceTextRef}
      >
        Doświadczenie zawodowe
      </Text>
      <Text
        position={[0.3, 1.45, -0.4]} // Pozycja wyświetlanego tekstu
        fontSize={0.12}
        rotation={[-Math.PI / 2, -Math.PI / 2, -Math.PI / 2]}
        color={"black"} // Używamy stanu koloru
        castShadow
        fontWeight={"bold"}
        fontStyle={"italic"}
      >
        {rightMonitorText}
      </Text>
      <Text
        position={[-2.4, 0.35, -0.4]} // Pozycja wyświetlanego tekstu
        fontSize={0.18}
        rotation={[Math.PI / 2, Math.PI, Math.PI / 2 + 0.5]}
        color={color4} // Używamy stanu koloru
        castShadow
        onPointerOver={() =>
          color4 === "#c0512d" ? null : setColor4("orange")
        }
        // Przywracamy kolor po opuszczeniu
        onPointerOut={() => (color4 === "#c0512d" ? null : setColor4("black"))}
        onClick={handleClick4}
        fontWeight={"bold"}
        fontStyle={"italic"}
      >
        Hobby
      </Text>
      <Text
        position={[-0.3, 0.35, -2.2]} // Pozycja wyświetlanego tekstu
        fontSize={0.1}
        rotation={[0, Math.PI * 2 - 0.4, 0]}
        color={"black"} // Używamy stanu koloru
        castShadow
        // Przywracamy kolor po opuszczeniu
        onClick={handleClick4}
        fontWeight={"bold"}
        fontStyle={"italic"}
      >
        {printText}
      </Text>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube052.geometry}
        material={materials["Material.014"]}
        position={[0.091, 0.997, -1.703]}
        rotation={[0, 0.141, 0]}
        scale={[1, 0.881, 0.957]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube010.geometry}
        material={materials["Material.039"]}
        position={[-0.142, 0.549, -0.088]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.13, 0.537]}
        onClick={handleClick} // Dodanie interakcji na kliknięcie
      />
      {/* Wyświetlanie tekstu warunkowo */}
      {showText && (
        <>
          <Text
            position={[-2.4, 2.4, -2.6]} // Pozycja wyświetlanego tekstu
            fontSize={0.22}
            color="white"
            castShadow
            ref={groupTextRef}
          >
            EDUKACJA
          </Text>
          <group position={[-1, 1.7, -2.8]}>
            <Text
              position={[0, 0.6, 0.2]}
              fontSize={0.15}
              lineHeight={1}
              color="#FFFFFF"
              castShadow
            >
              {"10.2009 – 06.2014 "}
            </Text>
            <Text
              position={[0, 0.3, 0.2]}
              fontSize={0.12}
              lineHeight={1}
              color="#222222"
              castShadow
            >
              {"Uniwersytet Marii-Curie Skłodowskiej \nw Lublinie"}
            </Text>
            <Text
              position={[-0.4, 0.05, 0.3]}
              fontSize={0.12}
              lineHeight={1}
              color="#ffffff"
              castShadow
            >
              {"Magisterka:"}
            </Text>
            <Text
              position={[-0.2, -0.05, 0.1]}
              fontSize={0.12}
              lineHeight={1}
              color="#222222"
              castShadow
            >
              {"\tInformatyka medyczna"}
            </Text>
            <Text
              position={[0, -0.25, 0.3]}
              fontSize={0.12}
              lineHeight={1}
              color="#ffffff"
              castShadow
            >
              {"Licencjat"}
            </Text>
            <Text
              position={[0.1, -0.35, 0.1]}
              fontSize={0.12}
              lineHeight={1}
              color="#222222"
              castShadow
            >
              {"\tInformatyka"}
            </Text>
          </group>
        </>
      )}
      {showText2 && (
        <>
          <Text
            position={[-2.5, 2.5, -2.7]} // Pozycja wyświetlanego tekstu
            fontSize={0.19}
            color="white"
            castShadow
            ref={groupTextRef}
          >
            {"DOŚWIADCZENIE  \nZAWODOWE"}
          </Text>
          <Text
            position={[-0.9, 2.5, -2.6]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="white"
            castShadow
          >
            {"01.2024– 05.2024"}
          </Text>
          <Text
            position={[-0.9, 2.4, -2.7]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="black"
            castShadow
          >
            {"Pyramid Game - Fullstack Developer"}
          </Text>
          <Text
            position={[-0.9, 2.1, -2.6]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="white"
            castShadow
          >
            {"07.2023– 12.2023"}
          </Text>
          <Text
            position={[-0.9, 2, -2.7]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="black"
            castShadow
          >
            {"SpeedApp - Frontend Developer"}
          </Text>
          <Text
            position={[-0.9, 1.7, -2.6]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="white"
            castShadow
          >
            {"04.2021– 01.2023"}
          </Text>
          <Text
            position={[-0.9, 1.6, -2.7]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="black"
            castShadow
          >
            {"Madmind - Mobile Developer"}
          </Text>
          <Text
            position={[-0.9, 1, -2.6]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="white"
            castShadow
          >
            {"11.2014– 11.2017"}
          </Text>
          <Text
            position={[-0.5, 0.8, -2.7]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="black"
            castShadow
          >
            {"Sulimo software \n Fullstack Web developer"}
          </Text>
          <Text
            position={[-0.9, 1.4, -2.6]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="white"
            castShadow
          >
            {"02.2015 – 10.2022"}
          </Text>
          <Text
            position={[-0.9, 1.2, -2.7]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="black"
            castShadow
          >
            {
              "Uniwersytet Marii-Curie Skłodowskiej\n w Lublinie, Asystent, Wykładowca"
            }
          </Text>
        </>
      )}
      {showText3 && (
        <group>
          <Text
            position={[-2.4, 2.4, -2.7]} // Pozycja wyświetlanego tekstu
            fontSize={0.19}
            color="white"
            castShadow
            ref={groupTextRef}
          >
            {"DOŚWIADCZENIE  \nW TECHNOLOGIACH"}
          </Text>
          <Text
            position={[-0.9, 2.5, -2.6]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="white"
            castShadow
          >
            {"React Native"}
          </Text>
          <Text
            position={[-0.9, 2.4, -2.7]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="black"
            castShadow
          >
            {"React"}
          </Text>
          <Text
            position={[-0.9, 2.1, -2.6]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="white"
            castShadow
          >
            {"TypeScript"}
          </Text>
          <Text
            position={[-0.9, 2, -2.7]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="black"
            castShadow
          >
            {"HTML/CSS"}
          </Text>
          <Text
            position={[-0.9, 1.7, -2.6]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="white"
            castShadow
          >
            {"node.js"}
          </Text>
          <Text
            position={[-0.9, 1.6, -2.7]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="black"
            castShadow
          >
            {"nest.js next.js"}
          </Text>
          <Text
            position={[-0.9, 1.3, -2.6]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="white"
            castShadow
          >
            {"Android/IOS"}
          </Text>
          <Text
            position={[-0.9, 1.2, -2.7]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="black"
            castShadow
          >
            {"Ant Design, Figma"}
          </Text>
        </group>
      )}

      {showText4 && (
        <>
          <Text
            position={[-2.4, 2.4, -2.6]} // Pozycja wyświetlanego tekstu
            fontSize={0.22}
            color="white"
            castShadow
            ref={groupTextRef}
          >
            {"HOBBY"}
          </Text>
          <Text
            position={[-0.9, 2.5, -2.6]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="white"
            castShadow
          >
            {"Programowanie aplikacji mobilnych"}
          </Text>
          <Text
            position={[-0.9, 2.3, -2.7]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="black"
            castShadow
          >
            {"Komponowanie muzyki"}
          </Text>
          <Text
            position={[-0.9, 2.1, -2.6]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="white"
            castShadow
          >
            {"Squash i piłka nożna"}
          </Text>
          <Text
            position={[-0.9, 1.9, -2.7]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="black"
            castShadow
          >
            {"Taniec: Bachata"}
          </Text>
          <Text
            position={[-0.9, 1.7, -2.6]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="white"
            castShadow
          >
            {"Gra na pianinie i gitarze"}
          </Text>
          <Text
            position={[-0.9, 1.5, -2.7]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="black"
            castShadow
          >
            {"Ogrodnictwo"}
          </Text>
          <Text
            position={[-0.9, 1.3, -2.6]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="white"
            castShadow
          >
            {"Lutnictwo"}
          </Text>
          <Text
            position={[-0.9, 1.1, -2.7]} // Pozycja wyświetlanego tekstu
            fontSize={0.12}
            lineHeight={1}
            opacity={1} // Ustaw przezroczystość
            color="black"
            castShadow
          >
            {"Elektronika"}
          </Text>
        </>
      )}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube019.geometry}
        material={nodes.Cube019.material}
        position={[-0.137, 0.323, -0.093]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.138, 0.537]}
        //pułka prawa 2 od dołu
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube020.geometry}
        material={nodes.Cube020.material}
        position={[-0.131, 0.118, -0.093]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.138, 0.537]}
        //pułka prawa 1 od dołu
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube025.geometry}
        material={nodes.Cube025.material}
        position={[-0.053, 0.179, -1.589]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.133, 0.41]}
        //pułka pod drukarką
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube026.geometry}
        material={nodes.Cube026.material}
        position={[0.043, 0.575, -1.589]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.133, 0.302]}
        //pułka pod przedłużaczem
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube035.geometry}
        material={nodes.Cube035.material}
        position={[-0.127, 0.81, -0.021]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.105, 0.571]}
        //prawy górny blat
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube003.geometry}
        material={nodes.Cube003.material}
        position={[0.046, 0.81, -1.039]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.457, 0.336]}
        //główny blat
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube041.geometry}
        material={materials["Material.033"]}
        position={[0.036, 0.327, -0.34]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.005, 0.289]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube040.geometry}
        material={materials["Material.037"]}
        position={[-0.184, 0.793, -0.843]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.546, 0.012]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube039.geometry}
        material={materials["Material.032"]}
        position={[0.038, 0.322, -1.347]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.005, 0.289]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube037.geometry}
        material={nodes.Cube037.material}
        position={[0.038, 0.327, 0.15]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.005, 0.289]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube036.geometry}
        material={nodes.Cube036.material}
        position={[0.036, 0.791, 0.15]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.005, 0.289]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube034.geometry}
        material={materials["Material.022"]}
        position={[-0.187, 0.399, -1.839]}
        rotation={[0, 0, -Math.PI]}
        scale={[0.012, 1, 0.288]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube032.geometry}
        material={nodes.Cube032.material}
        position={[0.261, 0.793, -0.843]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.546, 0.012]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube031.geometry}
        material={materials["Material.029"]}
        position={[0.261, 0.323, -0.843]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.546, 0.012]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube033.geometry}
        material={materials["Material.026"]}
        position={[-0.187, 0.398, 0.153]}
        scale={[0.012, 0.802, 0.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube024.geometry}
        material={materials["Material.021"]}
        position={[-0.188, 0.402, -1.346]}
        scale={[0.012, 0.792, 0.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube018.geometry}
        material={materials["Material.025"]}
        position={[-0.179, 0.398, -0.34]}
        scale={[0.012, 0.802, 0.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube006.geometry}
        material={materials["Material.038"]}
        position={[0.262, 0.792, -0.843]}
        rotation={[Math.PI / 2, 0, Math.PI]}
        scale={[0.366, 0.546, 0.012]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube002.geometry}
        material={materials["Material.023"]}
        position={[0.259, 0.399, -1.839]}
        rotation={[0, 0, -Math.PI]}
        scale={[0.012, 1, 0.288]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube001.geometry}
        material={materials["Material.035"]}
        position={[0.036, 0.791, -1.837]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.005, 0.289]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube.geometry}
        material={materials["Material.027"]}
        position={[0.262, 0.398, 0.153]}
        scale={[0.012, 0.802, 0.322]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube045.geometry}
        material={materials["Material.024"]}
        position={[0.259, 0.399, -1.346]}
        rotation={[0, 0, -Math.PI]}
        scale={[0.012, 1, 0.288]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube046.geometry}
        material={materials["Material.028"]}
        position={[0.259, 0.399, -0.34]}
        rotation={[0, 0, -Math.PI]}
        scale={[0.012, 1, 0.288]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube050.geometry}
        material={materials["Material.030"]}
        position={[0.036, 0.698, -0.34]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.005, 0.289]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube051.geometry}
        material={materials["Material.031"]}
        position={[0.036, 0.698, -1.348]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.005, 0.289]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube038.geometry}
        material={materials["Material.036"]}
        position={[-0.269, 0.791, -1.599]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.005, 0.101]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube049.geometry}
        material={materials["Material.034"]}
        position={[0.036, 0.322, -1.832]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.005, 0.289]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube048.geometry}
        material={nodes.Cube048.material}
        position={[-0.822, 0.74, -0.845]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.263, 0.012]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube047.geometry}
        material={nodes.Cube047.material}
        position={[-0.248, 0.737, -0.845]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.263, 0.012]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube044.geometry}
        material={materials["Material.020"]}
        position={[-0.245, 0.374, -1.315]}
        scale={[0.155, 0.971, 0.288]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube043.geometry}
        material={materials["Material.019"]}
        position={[-0.251, 0.373, -0.373]}
        scale={[0.155, 0.971, 0.288]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube042.geometry}
        material={materials["Material.018"]}
        position={[-0.537, 0.312, -0.372]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.005, 0.396]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube011.geometry}
        material={materials["Material.015"]}
        position={[-0.828, 0.373, -1.313]}
        scale={[0.155, 0.971, 0.288]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube012.geometry}
        material={materials["Material.017"]}
        position={[-0.828, 0.374, -0.373]}
        scale={[0.155, 0.971, 0.288]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube023.geometry}
        material={materials["Material.016"]}
        position={[-0.538, 0.31, -1.315]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.005, 0.396]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube005.geometry}
        material={nodes.Cube005.material}
        position={[-0.535, 0.738, -1.315]}
        rotation={[0, 0, -Math.PI]}
        scale={[0.407, 0.022, 0.288]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube004.geometry}
        material={nodes.Cube004.material}
        position={[-0.54, 0.739, -0.374]}
        rotation={[0, 0, -Math.PI]}
        scale={[0.407, 0.022, 0.288]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube009.geometry}
        material={nodes.Cube009.material}
        position={[-0.754, 0.756, -0.843]}
        rotation={[-Math.PI / 2, -1.571, 0]}
        scale={[0.366, 0.266, 0.269]}

        //przedni blat
      />
      <Select enabled={true}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube030.geometry}
          material={materials["Material.013"]}
          position={[-0.038, 0.608, -1.687]}
          scale={scale}
          //onPointerOver={() => setScale([0.523, 1.879, 1.209])}
          //onPointerLeave={() => setScale([0.423, 1.679, 1.009])}
          //przedluzacz
        />
      </Select>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube029.geometry}
        material={materials["Material.010"]}
        position={[-0.77, 0.769, -0.527]}
        scale={[0.692, 1, 0.989]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube028.geometry}
        material={materials["Material.009"]}
        position={[-0.775, 0.778, -0.946]}
        scale={[0.685, 0.525, 0.673]}
        //klawiatura
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube022.geometry}
        material={materials["Material.012"]}
        position={[-0.457, 0.6, -0.133]}
        scale={[0.825, 1.049, 1.089]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube021.geometry}
        material={materials["Material.011"]}
        position={[-0.456, 0.595, 0.075]}
        scale={[0.825, 1.049, 0.817]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube017.geometry}
        material={materials.Material}
        position={[-0.4, 0.813, -0.848]}
        scale={[1.179, 1.606, 0.954]}
        //klawisz piano pianino
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube015.geometry}
        material={materials["Material.004"]}
        position={[0.021, 0.997, 0.017]}
        rotation={[0, -0.124, 0]}
        scale={[1, 0.881, 0.957]}
        //prawy głośnik
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube013.geometry}
        material={materials["Material.006"]}
        position={[0.238, 1.06, -1.208]}
        rotation={[0, 0.061, -0.054]}
        scale={[0.529, 0.893, 1.167]}
        //monitor lewy ableton
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube014.geometry}
        onPointerOver={() => {
          setHovered(true)
          setRightMonitorText("Kliknij i zobacz moją aplikację!")
        }}
        onPointerOut={() => {
          setHovered(false)
          setRightMonitorText("")
        }}
        material={
          hovered ? materials["Material.010"] : materials["Material.007"]
        }
        position={[0.223, 1.06, -0.539]}
        rotation={[0, -0.084, -0.054]}
        scale={[0.529, 1.042, 1.354]}
        onClick={() => navigate("/app")}
        //monitor prawy vs code
      ></mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube027.geometry}
        material={materials["Material.008"]}
        position={[0.058, 0.286, -1.592]}
        scale={[0.779, 0.494, 0.644]}
        onPointerOver={() => setPrintText("wydrukować CV?")}
        onPointerLeave={() => setPrintText("")}
        onClick={() => DownloadButton()}
        //drukarka
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube008.geometry}
        material={materials["Material.002"]}
        position={[0.116, 0.232, -0.867]}
        scale={[0.597, 1.171, 0.762]}
        //piec line6
      />
    </group>
  )
}

useGLTF.preload("/3d/biurko2.gltf")
