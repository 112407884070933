import "./styles.css"
import { Suspense } from "react"
import { OrbitControls, Stars, Sparkles, Loader, Html } from "@react-three/drei"
import { Canvas, useFrame, useThree } from "@react-three/fiber"
import CloudCursor from "./CloudCursor"
import Model from "./Model"
import Model2 from "./Model2"
import Model3 from "./Model3"
import Model4 from "./Model4"
import Model5 from "./Model5"
import Model6 from "./Model6"
import React, { useState, useEffect, useRef, useMemo } from "react"

import { useSpring, a } from "@react-spring/three"

export default function App() {
  const mouse = useRef([0, 0])
  const [lights, setLights] = useState(1)

  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)
  const [aspectRatio, setAspectRatio] = useState(width / height)
  const [firstPositionCamera, setFirstPositionCamera] = useState(
    aspectRatio < 1 ? -12 : -4
  )

  const switchLight = () => {
    setLights(lights === 0 ? 1 : 0)
  }

  const [value, setValue] = useState("")

  const [animationIndex, setAnimationIndex] = useState(2)

  console.log("aspectRatio", aspectRatio)

  const orbitRef = useRef()

  useEffect(() => {
    console.log("animationIndex", animationIndex)
  }, [animationIndex])

  // Funkcja do przesuwania widoku w prawo
  const handleMoveRight = () => {
    if (orbitRef.current) {
      // Aktualny kąt azymutalny
      // const currentAzimuth = orbitRef.current.getAzimuthalAngle()

      // Ustawienie nowego kąta (przesunięcie w prawo)
      //orbitRef.current.setAzimuthalAngle(currentAzimuth + Math.PI / 6) // Przesunięcie o 10 stopni
      orbitRef.current.setAzimuthalAngle(-0.5) // Przesunięcie o 10 stopni
      orbitRef.current.update() // Zaktualizowanie widoku
    }
  }

  return (
    <div className="scene">
      <CloudCursor />
      <Canvas
        shadows
        camera={{
          position: [2, firstPositionCamera, 5],
          fov: 60,
        }}
      >
        <OrbitControls
          enableZoom={true}
          ref={orbitRef}
          azimuth={[-Math.PI / 4, Math.PI / 4]}
          zoomSpeed={-1}
          target={[5, 1, 3]}
          minAzimuthAngle={-Math.PI / 2} // Ograniczenie w lewo o 45 stopni
          maxAzimuthAngle={Math.PI / 6} // Ograniczenie w prawo o 45 stopni
          minPolarAngle={Math.PI / 2} // Blokada góra-dół
          maxPolarAngle={Math.PI / 2} // Blokada góra-dół
          minDistance={5}
          maxDistance={18}
          rotateSpeed={-1}
          enablePan={false}
        />
        <ambientLight
          color={"white"}
          intensity={lights}
          position={[1, 3, -5]}
        />

        <directionalLight
          position={[3, 3, 3]}
          intensity={1}
          color={"blue"}
          castShadow
        />

        <directionalLight
          position={[3, -3, 3]}
          intensity={1}
          color={"red"}
          castShadow
        />
        <directionalLight position={[-2, 2, 5]} intensity={1} color={"white"} />
        <directionalLight
          position={[-1, 0, -5]}
          intensity={1}
          color={"white"}
        />
        <directionalLight position={[5, 5, -5]} intensity={1} color={"white"} />

        <group position={[5, -1, 3]} rotation={[0, Math.PI, 0]}>
          <Suspense fallback={null}>
            <Model2
              scale={[2, 2, 2]}
              rotation={[0, Math.PI, 0]}
              position={[-3, 0, -2]}
              moveAngle={() => handleMoveRight()}
              selectAnimation={(item) => setAnimationIndex(item)}
            />
            <Model4 />
            <Model5
              position={[-4, 3, -3]}
              scale={[0.3, 0.3, 0.3]}
              onClick={() => switchLight()}
            />

            <Model6 animationIndex={animationIndex} />
          </Suspense>
        </group>
        <Suspense fallback={"Loading"}>
          <Stars
            radius={100}
            depth={100}
            count={4000}
            factor={4}
            saturation={0}
            fade
            speed={0.2}
          />
          <Sparkles
            count={100}
            size={5}
            speed={0.5}
            opacity={1}
            scale={10}
            color="#fff3b0"
            position={[6, 2, 2]}
          />
        </Suspense>
      </Canvas>
      <Loader />
    </div>
  )
}
