/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react"
import { useGLTF } from "@react-three/drei"

export default function Model(props) {
  const { nodes, materials } = useGLTF("/3d/Switch.gltf")
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.White_Switch_1.geometry}
        material={materials["White Switch"]}
        onClick={props.onClick}
      />
    </group>
  )
}

useGLTF.preload("/3d/Switch.gltf")
