import React, { useRef, Suspense } from "react"
import { useFrame } from "@react-three/fiber"
import { OrbitControls, Stars, Sparkles, Loader } from "@react-three/drei"
import "./styles.css"

const AnimatedBackground = () => {
  const meshRef = useRef()

  useFrame(({ clock }) => {
    if (meshRef.current) {
      meshRef.current.material.uniforms.uTime.value = clock.getElapsedTime()
    }
  })

  return (
    <mesh ref={meshRef}>
      {/* <planeBufferGeometry args={[10, 10, 100, 100]} /> */}
      <shaderMaterial
        attach="material"
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        uniforms={{
          uTime: { value: 0 },
          uColor1: { value: [0.3, 0.6, 0.9] },
          uColor2: { value: [0.9, 0.3, 0.6] },
        }}
      />
      <Suspense fallback={"Loading"}>
        <Stars
          radius={100}
          depth={100}
          count={400}
          factor={4}
          saturation={1}
          fade
          speed={3}
        />
        <Sparkles
          count={30}
          size={5}
          speed={0.5}
          opacity={1}
          scale={5}
          color="#fff3b0"
          position={[0, 0, 3]}
        />
      </Suspense>
    </mesh>
  )
}

// Shadery pozostają bez zmian
const vertexShader = `...`
const fragmentShader = `...`

export default AnimatedBackground
