import { useEffect, useRef } from "react"
import "./CloudCursor.css"

const FireCursor = () => {
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas?.getContext("2d")
    const particles = []
    let mouseX = 0
    let mouseY = 0

    canvas.width = window.innerWidth
    canvas.height = window.innerHeight

    const handleMouseMove = (e) => {
      mouseX = e.clientX
      mouseY = e.clientY
      createParticles(mouseX, mouseY)
    }

    function createParticles(x, y) {
      for (let i = 0; i < 15; i++) {
        particles.push({
          x,
          y,
          size: Math.random() * 5 + 1,
          color: `hsl(${Math.random() * 360}, 100%, 50%)`,
          opacity: 1,
          speedX: (Math.random() - 0.5) * 5,
          speedY: (Math.random() - 0.5) * 5,
        })
      }
    }

    function animateParticles() {
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      particles.forEach((particle, index) => {
        particle.x += particle.speedX
        particle.y += particle.speedY
        particle.opacity -= 0.02
        particle.size *= 0.96

        // Draw particle
        ctx.beginPath()
        ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2)
        ctx.fillStyle = particle.color
        ctx.globalAlpha = particle.opacity
        ctx.fill()

        // Remove particle once it fades out
        if (particle.opacity <= 0) {
          particles.splice(index, 1)
        }
      })

      requestAnimationFrame(animateParticles)
    }

    // Event listeners
    document.addEventListener("mousemove", handleMouseMove)
    animateParticles()

    return () => {
      document.removeEventListener("mousemove", handleMouseMove)
    }
  }, [])

  return <canvas ref={canvasRef} className="fire-cursor-canvas"></canvas>
}

export default FireCursor
