import React, { useEffect, useRef } from "react"
import "./UserList.css"

// Tablica obiektów z unikalnymi opisami
const users = [
  {
    id: 1,
    image: "/app/3.png",
    description:
      "Logowanie: \nFormularz wykorzystujący react hook forms, z walidacją błędnego hasła lub nazwy użytkownik. Po poprawnym zalogowaniu użytkownik dostaje token który umożliwia przejśćie do części dla użytkowników zalogowanych.",
  },
  {
    id: 2,
    image: "/app/2.png",
    description:
      "Profil użytkownika: \n W tym miejscu użytkownik może się wylogować lub edytować profil. Przycisk na dole jest pływającym przyciskiem po kliknięciu wysuwa dostępne opcje. Ponowne kliknięcie zwija wszystkie opcje.",
  },
  {
    id: 3,
    image: "/app/1.png",
    description:
      "Strona domowa:\n Loadery zostały wykonane w formie ruchomych skeletons. O tym czy użytkownik jest zalogowany decyduje token, który jest odświeżany co godzinę.",
  },
  {
    id: 4,
    image: "/app/4.png",
    description:
      "Rejestracja: \nFormularz react hook forms z walidacją zarówno po stronie frontendu jak i backendu, frontend pilnuje pól które nie mogą być puste natomiast backend sprawdza czy użytkownik już istnieje i zwraca stosowny komunikat.",
  },
  {
    id: 5,
    image: "/app/5.png",
    description:
      "Widok załadowanych danych, są to dwie karuzele z możliwością wejścia w konkretny item.",
  },
  {
    id: 6,
    image: "/app/6.png",
    description:
      "Widok użytkownika:\n rozwijane menu umożliwia kilka akcji, każda akcja wysyła zmiany do backendu oraz odświeża się po każdej wykonanej akcji",
  },
  {
    id: 7,
    image: "/app/7.png",
    description:
      "Lista eventów: \nLista jest paginowana w sposób tzw. infinite scroll, domyślnie ma loadery typu skeleton, każde przewinięcie doładowuje kolejne elementy. ",
  },
  {
    id: 8,
    image: "/app/8.png",
    description:
      "Filtrowanie: \nLista jest filtrowana po kilku parametrach, to czy jest aktywny filtr jest widoczny poprzez czerwoną kropkę. Sortowanie działa w ten sam sposób.",
  },
  {
    id: 9,
    image: "/app/9.png",
    description:
      "Folowings: \nLista użytkowników którzy mnie obserwują jest paginowana i odświeżana co 3 sekundy. Wszędzie wykorzystany jest react-query w wersji 5.x",
  },
  {
    id: 10,
    image: "/app/10.png",
    description:
      "Friends: \nLista znajomych odświeżana jest co 3 sekundy lub w momencie ingerencji w status znajomości, ikonka zegara symbolizuje że użytkownik jeszcze nie zaakceptował znajomości.",
  },
  {
    id: 11,
    image: "/app/11.png",
    description:
      "Czaty: \nPaginowana lista czatów, odświeżana jest co 3 sekundy, w przypadku nowej wiadomości przy ikonce w tab nawigatorze pojawi się czerwona kropka, a na liście dany użytkownik będzie podkreślony na czerwono",
  },
  {
    id: 12,
    image: "/app/12.png",
    description: "Widok czatu: \nWiadomości wysyłane są za pomocą websocket",
  },
  {
    id: 16,
    image: "/app/16.png",
    description:
      "Edycja: \nDane użytkownika zapisane są w asyncStorage sterowane za pomocą kontekstu, folmularz posiada walidację i autouzupełnianie",
  },
  {
    id: 17,
    image: "/app/17.png",
    description:
      "Brak połączenia: \nAplikacja informuje o braku dostępu do api spowodowany innym błędem niż brak autoryzacji, powtarza póki nie uzyska dostępu",
  },
  {
    id: 18,
    image: "/app/18.png",
    description:
      "BottomSheet: \nKażde zaproszenie można odrzucić zanim drugi użytkownik zaakceptuje",
  },
  {
    id: 19,
    image: "/app/19.png",
    description:
      "Widok swipe: \nGórna belka jest poziomą flatlistą z paginacją i skeleton podczas wczytywania, komponent pomiżej imituje popularne aplikacje randkowe",
  },
  {
    id: 20,
    image: "/app/20.png",
    description: "Widok filtrów: \nFiltry zapisane są za pomocą redux toolkit",
  },
  {
    id: 21,
    image: "/app/21.png",
    description:
      "Backend: \nBackend napisany został w nestJS i posiada pełną dokumentację.",
  },
  {
    id: 22,
    image: "/app/22.png",
    description: "Backend: \n Cała aplikacja zawiera ponad 50 endpointów",
  },
  {
    id: 23,
    image: "/app/23.png",
    description:
      "Baza danych: \n Baza danych postawiona jest na mongoDB, backned korzysta z biblioteki mongoose, oraz jest zabezpieczony aktualnymi tokenami z możliwośćią odświeżenia",
  },
]

const UserList = () => {
  const refs = useRef([])

  useEffect(() => {
    const handleScroll = () => {
      refs.current.forEach((ref) => {
        if (ref) {
          const rect = ref.getBoundingClientRect()
          const isVisible = rect.top < window.innerHeight && rect.bottom >= 0
          if (isVisible) {
            ref.classList.add("visible")
          } else {
            ref.classList.remove("visible")
          }
        }
      })
    }

    window.addEventListener("scroll", handleScroll)
    handleScroll() // Wywołaj natychmiast, aby sprawdzić widoczność na początku
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <div className="user-list">
      {users.map((user, index) => (
        <div key={user.id} className="user-item">
          <img
            src={user.image}
            alt={`User ${user.id}`}
            className="user-image"
          />
          <div className="description-container">
            <p
              className="user-description"
              ref={(el) => (refs.current[index] = el)}
            >
              {user.description.split("\n").map((line, lineIndex) => (
                <p key={lineIndex}>{line}</p>
              ))}
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default UserList
