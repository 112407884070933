import React from "react"
import UserList from "./UserList"
import AnimatedBackground from "./AnimatedBackground"
import { Canvas } from "@react-three/fiber"

const AppPageWithBackground = () => {
  return (
    <div>
      <Canvas
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
        }}
      >
        <AnimatedBackground />
      </Canvas>
      <div
        style={{
          zIndex: 1000,
          background: "linear-gradient(180deg, #120726,#120133)",
        }}
      >
        <UserList />
      </div>
    </div>
  )
}

export default AppPageWithBackground
