/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react"
import { useGLTF } from "@react-three/drei"

export default function Model(props) {
  const { nodes, materials } = useGLTF("/3d/lawka3.gltf")
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube033.geometry}
        material={materials["Material.034"]}
        position={[-0.158, 0.117, 0.478]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.033, 1.733, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube036.geometry}
        material={materials["Material.044"]}
        position={[0.682, 0.366, -0.391]}
        rotation={[Math.PI / 2, -1.571, 0]}
        scale={[0.033, 0.545, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube038.geometry}
        material={materials["Material.045"]}
        position={[0.679, 0.559, -0.619]}
        rotation={[-0.19, 0, Math.PI]}
        scale={[0.033, 0.396, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube039.geometry}
        material={materials["Material.048"]}
        position={[-0.963, 0.563, -0.619]}
        rotation={[-0.19, 0, Math.PI]}
        scale={[0.033, 0.396, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube040.geometry}
        material={materials["Material.046"]}
        position={[-0.167, 0.558, -0.619]}
        rotation={[-0.19, 0, Math.PI]}
        scale={[0.033, 0.396, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube041.geometry}
        material={materials["Material.038"]}
        position={[-0.146, 0.532, -0.572]}
        rotation={[-0.19, 0, -Math.PI / 2]}
        scale={[0.08, 1.678, 0.447]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube042.geometry}
        material={materials["Material.036"]}
        position={[-0.146, 0.407, -0.289]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.017, 1.678, 2.145]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube043.geometry}
        material={materials["Material.035"]}
        position={[-0.146, 0.407, -0.132]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.017, 1.678, 2.145]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube044.geometry}
        material={materials["Material.039"]}
        position={[-0.146, 0.709, -0.601]}
        rotation={[-0.19, 0, -Math.PI / 2]}
        scale={[0.08, 1.678, 0.447]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube045.geometry}
        material={materials["Material.037"]}
        position={[-0.146, 0.407, -0.453]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.017, 1.678, 2.145]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube046.geometry}
        material={materials["Material.053"]}
        position={[-0.96, 0.366, -0.4]}
        rotation={[Math.PI / 2, -1.571, 0]}
        scale={[0.033, 0.545, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube001.geometry}
        material={materials["Material.050"]}
        position={[-1.019, 0.286, -0.641]}
        rotation={[Math.PI / 2, -1.571, 0]}
        scale={[0.399, 0.05, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube002.geometry}
        material={materials["Material.051"]}
        position={[-1.019, 0.295, -0.192]}
        rotation={[Math.PI / 2, -1.571, 0]}
        scale={[0.399, 0.05, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube004.geometry}
        material={materials["Material.049"]}
        position={[-1.022, 0.613, -0.42]}
        rotation={[Math.PI / 2, -1.571, 0]}
        scale={[0.033, 0.495, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube005.geometry}
        material={materials["Material.052"]}
        position={[-1.022, 0.22, -0.421]}
        rotation={[Math.PI / 2, -1.571, 0]}
        scale={[0.033, 0.396, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube018.geometry}
        material={materials["Material.042"]}
        position={[0.731, 0.287, -0.641]}
        rotation={[Math.PI / 2, -1.571, 0]}
        scale={[0.399, 0.05, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube006.geometry}
        material={materials["Material.043"]}
        position={[0.73, 0.22, -0.415]}
        rotation={[Math.PI / 2, -1.571, 0]}
        scale={[0.033, 0.396, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube023.geometry}
        material={materials["Material.031"]}
        position={[0.74, 0.327, 0.194]}
        rotation={[Math.PI / 2, -1.571, 0]}
        scale={[0.485, 0.05, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube024.geometry}
        material={materials["Material.028"]}
        position={[-1.049, 0.327, 0.218]}
        rotation={[Math.PI / 2, -1.571, 0]}
        scale={[0.485, 0.05, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube031.geometry}
        material={materials["Material.020"]}
        position={[-0.146, 0.701, 0.173]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.017, 1.921, 2.503]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube032.geometry}
        material={materials["Material.021"]}
        position={[-0.146, 0.701, 0.319]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.017, 1.921, 2.503]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube037.geometry}
        material={materials["Material.022"]}
        position={[-0.146, 0.701, 0.471]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.017, 1.921, 2.503]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube047.geometry}
        material={materials["Material.023"]}
        position={[-0.146, 0.701, 0.62]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.017, 1.921, 2.503]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube048.geometry}
        material={materials["Material.033"]}
        position={[0.74, 0.327, 0.745]}
        rotation={[Math.PI / 2, -1.571, 0]}
        scale={[0.485, 0.05, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube049.geometry}
        material={materials["Material.030"]}
        position={[-1.049, 0.327, 0.774]}
        rotation={[Math.PI / 2, -1.571, 0]}
        scale={[0.485, 0.05, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube050.geometry}
        material={materials["Material.029"]}
        position={[-1.055, 0.123, 0.5]}
        rotation={[Math.PI / 2, -1.571, 0]}
        scale={[0.033, 0.495, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube051.geometry}
        material={materials["Material.032"]}
        position={[0.739, 0.123, 0.476]}
        rotation={[Math.PI / 2, -1.571, 0]}
        scale={[0.033, 0.495, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube052.geometry}
        material={materials["Material.026"]}
        position={[-1.055, 0.666, 0.496]}
        rotation={[Math.PI / 2, -1.571, 0]}
        scale={[0.033, 0.495, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube053.geometry}
        material={materials["Material.027"]}
        position={[0.739, 0.664, 0.473]}
        rotation={[Math.PI / 2, -1.571, 0]}
        scale={[0.033, 0.495, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube034.geometry}
        material={materials["Material.047"]}
        position={[-0.159, 0.366, -0.315]}
        rotation={[Math.PI / 2, -1.571, 0]}
        scale={[0.033, 0.495, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube012.geometry}
        material={materials["Material.041"]}
        position={[0.724, 0.284, -0.189]}
        rotation={[Math.PI / 2, -1.571, 0]}
        scale={[0.399, 0.05, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube011.geometry}
        material={materials["Material.040"]}
        position={[0.722, 0.608, -0.414]}
        rotation={[Math.PI / 2, -1.571, 0]}
        scale={[0.033, 0.495, 0.894]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube054.geometry}
        material={materials["Material.024"]}
        position={[-0.146, 0.701, 0.771]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.017, 1.921, 2.503]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube057.geometry}
        material={materials["Material.025"]}
        position={[-0.146, 0.676, 0.481]}
        rotation={[-Math.PI / 2, 1.571, 0]}
        scale={[0.017, 0.693, 2.682]}
      />
    </group>
  )
}

useGLTF.preload("/3d/lawka3.gltf")
